@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Open+Sans&family=Rubik&family=Ubuntu&display=swap');
@font-face {
  font-family: 'Helvetica-Neue';
  src: local('HelveticaNeueRoman'),
    url('./asserts/fonts/helvetica-neue/HelveticaNeueRoman.otf')
      format('truetype');
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica-Neue',
    'Light' sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
